import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/building.css";
import "../../styles/grid_layout.css";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { ReactComponent as On } from "../../svg/on.svg";
import { ReactComponent as Off } from "../../svg/off.svg";
import BtnEntry from "../BtnEntry";

interface IOneRoom {
    key: number;
    id: number;
    name: string;
    rosette: boolean;
    lighting: boolean;
    currTemp: number;
    currPower: number;
}

const OneRoom: React.FC<IOneRoom> = ({
    id,
    name,
    rosette,
    lighting,
    currTemp,
    currPower,
}: IOneRoom) => {
    const navigate = useNavigate();
    let objIds = useParams();

    return (
        <div className="col col-4">
            <div className="viewBox roomItem">
                <div className="roomHeader">
                    <span className="roomName">{name}</span>
                    <BtnEntry
                        isActive={true}
                        extraStyle={{ marginRight: "20px" }}
                        text="Control"
                        onClickAction={() =>
                            navigate(
                                `/buildings/${objIds.buildingId}/rooms/${id}`
                            )
                        }
                    />
                </div>
                <div className="hLine"></div>
                <div className="roomStates">
                    <div className="rosetteLight">
                        <div className="state">
                            {rosette ? <On /> : <Off />}
                            <span>Power Sockets</span>
                        </div>
                        <div className="state">
                            {lighting ? <On /> : <Off />}
                            <span>Lighting</span>
                        </div>
                        <div className="state">
                            <Off />
                            <span>Automation</span>
                        </div>
                    </div>
                    <div className="tempPower">
                        <div className="state num">
                            <ThermostatOutlinedIcon
                                style={{
                                    fill: "#4092F5",
                                    transform: "scale(1.5)",
                                }}
                            />
                            <span>{currTemp.toFixed(1)} C°</span>
                        </div>
                        <div className="state num">
                            <BoltOutlinedIcon
                                style={{
                                    fill: "#4092F5",
                                    transform: "scale(1.5)",
                                }}
                            />
                            <span>{currPower} kWh</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OneRoom;
