import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInterval } from "../utils/useInterval";
import TopBar from "../containers/TopBar";
import Ventilation from "../components/Building/BVentilation";
import Automation from "../components/Building/BAutomation";
import OneRoom from "../components/Building/OneRoom";
import { IRoom, IBuilding } from "../interfaces";
import Box from "@mui/material/Box";
import { getData } from "../api/useApi";

const Building: React.FC = () => {
    const objIds = useParams();
    const urlPath = `/buildings/${objIds.buildingId}`;
    const urlToBack = `/buildings`;
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [building, setBuilding] = useState<IBuilding>({
        id: 0,
        name: "",
        averageTemp: 0,
        currPower: 0,
        ventilation: {
            currTemp: 0,
            setTemp: 0,
            setSpeed: 0,
            minSpeed: 0,
            maxSpeed: 0,
        },
        rooms: [],
    });

    useEffect(() => {
        getData(urlPath, setBuilding, {});
    }, [urlPath, isUpdated]);

    useInterval(() => {
        return setIsUpdated(!isUpdated);
    }, 3000);

    return building ? (
        <Box sx={{ minWidth: "800px" }}>
            <TopBar
                isBackBtn
                headerName={building.name || " "}
                isEditBtn
                backLink={urlToBack}
            />
            <Box className="row two">
                <Ventilation
                    currTemp={building.ventilation.currTemp! || 0}
                    setTemp={building.ventilation.setTemp! || 0}
                    setSpeed={building.ventilation.setSpeed! || 0}
                    minSpeed={building.ventilation.minSpeed! || 0}
                    maxSpeed={building.ventilation.maxSpeed! || 0}
                />
                <Automation />
            </Box>
            <Box className="row three">
                {building.rooms.map((v: IRoom) => {
                    return (
                        <OneRoom
                            key={v.id! || 0}
                            id={v.id! || 0}
                            name={v.name! || ""}
                            rosette={v.rosette! || false}
                            lighting={v.lighting! || false}
                            currTemp={v.currTemp! || 0}
                            currPower={v.currPower! || 0}
                        />
                    );
                })}
            </Box>
        </Box>
    ) : null;
};

export default Building;
