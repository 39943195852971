import React from "react";
import "../../styles/building.css";
import "../../styles/grid_layout.css";
// import { ReactComponent as RAuto } from '../../svg/rAuto.svg';

const RAutomation: React.FC = () => {
    return (
        <div className="col col-3">
            <div
                className="viewBox WhiteTemplate"
                style={{ background: "#F4F4F4" }}
            >
                <div className="TemplateIconText">
                    {/* <RAuto /> */}
                    {/* <div className="TemplateText">
                        Автоматизация
                    </div> */}
                </div>
                <div id="AutoButtonSwitch">
                    {/* <button id="AutoButton">
                        Настроить
                    </button>
                    <div id="AutoSwitch">
                        <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default RAutomation;
