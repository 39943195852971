import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/building.css";
import "../../styles/grid_layout.css";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import { paletteColor } from "../../utils/paletteColors";
import { putData } from "../../api/useApi";

interface ISetTemp {
    currentSetTemp: number;
}

const SetTemp: React.FC<ISetTemp> = ({ currentSetTemp }: ISetTemp) => {
    let objIds = useParams();
    const urlPath = `/buildings/${objIds.buildingId}/rooms/${objIds.roomId}`;
    const [setTemp, setSetTemp] = useState(currentSetTemp);

    useEffect(() => {
        setSetTemp(currentSetTemp);
    }, [currentSetTemp]);

    function handleBtnClick(num: number) {
        if (setTemp + num > 40 || setTemp + num < 0) {
            return;
        }
        setSetTemp(setTemp + num);
        putData(urlPath, { setTemp: setTemp + num });
    }

    return (
        <div className="col col-3">
            <div className="viewBox WhiteTemplate">
                <div className="TemplateIconText">
                    <ThermostatOutlinedIcon
                        style={{
                            fill: paletteColor.LIGHT_BLUE,
                            transform: "scale(3)",
                        }}
                    />
                    <div className="TemplateText">Set Temperature C°</div>
                </div>
                <div id="SetTempPlusMinus">
                    <span id="PlusMinusText">{setTemp}</span>
                    <div id="TempPlusMinus">
                        <button
                            className="Plus"
                            onClick={() => handleBtnClick(1)}
                        >
                            +
                        </button>
                        <button
                            className="Minus"
                            onClick={() => handleBtnClick(-1)}
                        >
                            -
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetTemp;
