import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInterval } from "../utils/useInterval";
import "../styles/grid_layout.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TopBar from "../containers/TopBar";
import Temp from "../components/Ventilation/Temp";
import SetTemp from "../components/Ventilation/SetTemp";
import SetSpeed from "../components/Ventilation/SetSpeed";
import { IVentilation } from "../interfaces";
import { getData } from "../api/useApi";

const ControlVentilation: React.FC = () => {
    const objIds = useParams();
    const urlPath = `/buildings/${objIds.buildingId}/ventilation`;
    const urlToBack = `/buildings/${objIds.buildingId}`;
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [ventilation, setVentilation] = useState<IVentilation>({
        currTemp: 0,
        setTemp: 0,
        setSpeed: 0,
        minSpeed: 0,
        maxSpeed: 0,
    });

    useEffect(() => {
        const controller = new AbortController();
        getData(urlPath, setVentilation, controller);
        return () => controller.abort();
    }, [urlPath, isUpdated]);

    useInterval(() => {
        setIsUpdated(!isUpdated);
    }, 3000);

    return (
        <Box>
            <Grid item xs={12}>
                <TopBar
                    isBackBtn
                    headerName="Office Ventilation"
                    isEditBtn
                    backLink={urlToBack}
                />
            </Grid>
            <div className="row two">
                <Temp currentTemp={ventilation.currTemp || 0} />
                <SetTemp currentSetTemp={ventilation.setTemp || 0} />
                <SetSpeed
                    currentSetSpeed={ventilation.setSpeed || 0}
                    minSpeed={ventilation.minSpeed}
                    maxSpeed={ventilation.maxSpeed}
                />
            </div>
        </Box>
    );
};

export default ControlVentilation;
