import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/ventilation.css";
import "../../styles/grid_layout.css";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import { paletteColor } from "../../utils/paletteColors";
import { putData } from "../../api/useApi";

interface ISetTemp {
    currentSetTemp: number;
}

const SetTemp: React.FC<ISetTemp> = ({ currentSetTemp }: ISetTemp) => {
    const objIds = useParams();
    // const url = `${process.env.REACT_APP_URL_DATA_API}/buildings/${objIds.buildingId}/ventilation`;
    const urlPath = `/buildings/${objIds.buildingId}/ventilation`;
    const [setTemp, setSetTemp] = useState(currentSetTemp);

    useEffect(() => {
        setSetTemp(currentSetTemp);
    }, [currentSetTemp]);

    function handleBtnClick(num: number) {
        if (setTemp + num > 50 || setTemp + num < 0) {
            return;
        }
        setSetTemp(setTemp + num);
        putData(urlPath, { setTemp: setTemp + num });
    }

    return (
        <div className="col col-3">
            <div className="viewBox SetTemp">
                <div id="TempIconText">
                    <ThermostatOutlinedIcon
                        style={{
                            fill: paletteColor.LIGHT_BLUE,
                            transform: "scale(3)",
                        }}
                    />
                    <div id="TempTextTemplate">
                        Set
                        <br />
                        Temperature C°
                    </div>
                </div>
                <div className="SetPlusMinus">
                    <span className="PlusMinusText">{setTemp}</span>
                    <div className="PlusMinus">
                        <button
                            className="Plus"
                            onClick={() => handleBtnClick(1)}
                        >
                            +
                        </button>
                        <button
                            className="Minus"
                            onClick={() => handleBtnClick(-1)}
                        >
                            -
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetTemp;
