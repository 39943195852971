import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInterval } from "../utils/useInterval";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TopBar from "../containers/TopBar";
import TempPower from "../components/Room/TempPower";
import Rosette from "../components/Room/Rosette";
import Lighting from "../components/Room/Lighting";
import SetTemp from "../components/Room/SetTemp";
import RAutomation from "../components/Room/RAutomation";
import { IRoom } from "../interfaces";
import "../styles/grid_layout.css";
import "../styles/room.css";
import { getData } from "../api/useApi";

const ControlRoom: React.FC = () => {
    let objIds = useParams();
    const urlPath = `/buildings/${objIds.buildingId}/rooms/${objIds.roomId}`;
    const urlToBack = `/buildings/${objIds.buildingId}`;
    const [updateEvent, setUpdateEvent] = useState<boolean>(false);
    const [room, setRoom] = useState<IRoom>({
        id: 0,
        name: "",
        currTemp: 0,
        currPower: 0,
        rosette: true,
        lighting: true,
        setTemp: 0,
        heatingState: false,
    });

    useEffect(() => {
        const controller = new AbortController();
        getData(urlPath, setRoom, controller);
    }, [urlPath, updateEvent]);

    useInterval(() => {
        setUpdateEvent(!updateEvent);
    }, 3000);

    return (
        <Box>
            <Grid item xs={12}>
                <TopBar
                    isBackBtn
                    headerName={room.name}
                    isEditBtn
                    backLink={urlToBack}
                />
            </Grid>
            <div className="row">
                <TempPower
                    currentTemp={room.currTemp || 0}
                    currentPower={room.currPower || 0}
                />
                <div className="col col-6 column nowrap">
                    <div className="row">
                        <Rosette rosette={room.rosette} />
                        <Lighting lighting={room.lighting} />
                    </div>
                    <div className="row">
                        <SetTemp currentSetTemp={room.setTemp || 0} />
                        <RAutomation />
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default ControlRoom;
