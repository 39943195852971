import React, { useState, useEffect } from "react";
import { useInterval } from "../utils/useInterval";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TopBar from "../containers/TopBar";
import BuildingRow from "../containers/BuildingRow";
import { IBuildings, IBuilding } from "../interfaces";
import { getData } from "../api/useApi";

const BuildingsList: React.FC = () => {
    const urlPath = `/buildings`;
    const [isUpdated, setIsUpdated] = useState<boolean>(false);
    const [buildings, setBuildings] = useState<IBuildings>([]);

    useEffect(() => {
        getData(urlPath, setBuildings, {});
    }, [urlPath, isUpdated]);

    useInterval(() => {
        return setIsUpdated(!isUpdated);
    }, 3000);

    return (
        <Box className="noselect" sx={{ width: "100%" }}>
            <Grid container rowSpacing={0} columnSpacing={0}>
                <Grid item xs={12} /* sm={12} md={12} lg={12} xl={12} */>
                    <TopBar isMenuBtn headerName="Building List" isEditBtn />
                </Grid>

                <Grid item xs={12}>
                    {Array.isArray(buildings)
                        ? buildings.map((v: IBuilding) => {
                              return (
                                  <BuildingRow
                                      key={v.id}
                                      id={v.id}
                                      name={v.name || ""}
                                      currTemp={v.ventilation.currTemp || 0}
                                      setTemp={v.ventilation.setTemp || 0}
                                      setSpeed={v.ventilation.setSpeed || 0}
                                      minSpeed={v.ventilation.minSpeed || 0}
                                      maxSpeed={v.ventilation.maxSpeed || 0}
                                      currPower={v.currPower || 0}
                                  />
                              );
                          })
                        : null}
                </Grid>
            </Grid>
        </Box>
    );
};

export default BuildingsList;
