import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { paletteColor } from "../utils/paletteColors";
import "../styles/grid_layout.css";
import "../styles/buildings.scss";
import BtnEntry from "../components/BtnEntry";

interface IBuildingRow {
    id: number;
    name: string;
    currTemp: number;
    setTemp: number;
    setSpeed: number;
    minSpeed: number;
    maxSpeed: number;
    currPower: number;
}

const BuildingRow: React.FC<IBuildingRow> = ({
    id,
    name,
    currTemp,
    setTemp,
    setSpeed,
    minSpeed,
    maxSpeed,
    currPower,
}: IBuildingRow) => {
    const navigate = useNavigate();

    const iconSyle = {
        width: "50px",
        height: "50px",
        marginLeft: "10px",
        fill: paletteColor.LIGHT_BLUE,
    };

    const viewBoxStyle = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        // justifyContent: "space-between",
        // alignItems: "center",
        background: paletteColor.WHITE,
        borderRadius: "10px",
        width: "auto",
        minHeight: "96px",
        // boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
        boxShadow:
            "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
        margin: "10px 9px",
    };

    const rowBlock = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    };

    const paramBlock = {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
    };

    const paramTextName = {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.8125rem",
        color: paletteColor.LIGHT_BLUE,
    };

    const paramValue = {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.1875rem",
        color: paletteColor.LIGHT_BLUE,
        whiteSpace: "nowrap",
    };

    // const headerBoxStyle = {
    //     order: { xs: -1 },
    //     minWidth: "120px",
    //     marginLeft: "32px",
    //     color: paletteColor.DARK_BLUE,
    // };

    return (
        // <Grid
        //     item
        //     container
        //     xs={12}
        //     /* sm={12} md={12} lg={12} */ sx={viewBoxStyle}
        // >
        // <Box
        //     sx={{
        //         // position: "absolute",
        //         display: "flex",
        //         flexDirection: "row",
        //         flexWrap: "nowrap",
        //         justifyContent: "space-between",
        //         width: "100vw",
        //     }}
        // ></Box>

        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
                // width: "100vw",
                width: "auto",
                minWidth: "1000px",
                boxShadow:
                    "rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px",
                margin: "10px 9px",
                minHeight: "96px",
                background: paletteColor.WHITE,
                borderRadius: "10px",
            }}
        >
            <Box
                sx={{
                    ...rowBlock,
                    // minWidth: "120px",
                    marginLeft: "32px",
                    color: paletteColor.DARK_BLUE,
                    left: 0,
                }}
            >
                <h2 style={{ fontSize: "1.2em" }}>{name}</h2>
            </Box>
            <Grid
                item
                xs={12}
                sm={8}
                md={4}
                lg={3}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <ThermostatOutlinedIcon sx={{ ...iconSyle }} />
                <Box id="CurrTemp" sx={paramBlock}>
                    <Box id="BuildingCurrTempText" sx={paramTextName}>
                        Current <br />
                        Temperature
                    </Box>
                    <Box id="BuildingCurrTempNum" sx={paramValue}>
                        {currTemp} C°
                    </Box>
                </Box>
                <Box id="SetTemp" sx={paramBlock}>
                    <Box id="SetTempText" sx={paramTextName}>
                        Set <br />
                        Temperature
                    </Box>
                    <Box id="SetTempNum" sx={paramValue}>
                        {setTemp} C°
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                sm={9}
                md={6}
                lg={5}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <AirOutlinedIcon sx={{ ...iconSyle }} />
                <Box id="Vent" sx={paramBlock}>
                    <Box id="VentTextNum">
                        <Box id="VentText" sx={paramTextName}>
                            Set FAN
                            <br />
                            Speed {minSpeed}-{maxSpeed}
                        </Box>
                        <Box id="VentNum" sx={paramValue}>
                            {setSpeed} x
                        </Box>
                    </Box>
                </Box>

                <BoltOutlinedIcon sx={{ ...iconSyle }} />
                <Box id="Power" sx={paramBlock}>
                    <Box id="PowerTextNum">
                        <Box id="PowerText" sx={paramTextName}>
                            Total <br />
                            Power consumption
                        </Box>
                        <Box id="PowerNum" sx={paramValue}>
                            {currPower} kWh
                        </Box>
                    </Box>
                </Box>

                <PendingActionsIcon
                    sx={{ ...iconSyle, fill: paletteColor.GRAY }}
                />
                <Box id="Automation" sx={paramBlock}>
                    <Box id="AutoTextNum">
                        <Box id="AutoText" sx={paramTextName}>
                            Overall <br />
                            Automation
                        </Box>
                        <Box id="AutoOnOffText" sx={paramValue}>
                            Not available
                        </Box>
                    </Box>
                </Box>
            </Grid>

            <Box sx={{ ...rowBlock, marginRight: "20px" }}>
                <BtnEntry
                    isActive={true}
                    extraStyle={{}}
                    text="Control"
                    onClickAction={() => navigate(`/buildings/${id}`)}
                />
            </Box>
        </Box>
        // </Grid>
    );
};

export default BuildingRow;
