import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BuildingsList from "./pages/BuildingsList";
import BuildingDash from "./pages/BuildingDash";
import ControlVentilation from "./pages/ControlVentilation";
import ControlRoom from "./pages/ControlRoom";
import Login from "./pages/Login";

function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    {/* <Route path="login" element={<Login />} /> */}
                    <Route path="buildings" element={<BuildingsList />} />
                    <Route
                        path="buildings/:buildingId"
                        element={<BuildingDash />}
                    />
                    <Route
                        path="buildings/:buildingId/ventilation"
                        element={<ControlVentilation />}
                    />
                    <Route
                        path="buildings/:buildingId/rooms/:roomId"
                        element={<ControlRoom />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/buildings" />}
                        // element={
                        //     <main style={{ padding: "1rem" }}>
                        //         <p>There's nothing here!</p>
                        //     </main>
                        // }
                    />
                    <Route index element={<Navigate to="/buildings" />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;
