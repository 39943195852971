import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/grid_layout.css";
import "../../styles/building.css";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import BtnEntry from "../BtnEntry";

interface IBVentilation {
    currTemp: number;
    setTemp: number;
    setSpeed: number;
    minSpeed: number;
    maxSpeed: number;
}

const BVentilation: React.FC<IBVentilation> = ({
    currTemp,
    setTemp,
    setSpeed,
    minSpeed,
    maxSpeed,
}: IBVentilation) => {
    let objIds = useParams();
    const navigate = useNavigate();

    return (
        <div className="col col-8">
            <div className="viewBox ventilation">
                <div className="iconTextState">
                    <div className="iconText">
                        <AirOutlinedIcon
                            style={{ fill: "white", transform: "scale(1.8)" }}
                        />
                        <span>
                            Office <br />
                            Ventilation
                        </span>
                    </div>
                    <div className="state">
                        {/* <img src="../svg/OffIcon.svg" /> */}
                        {/* <span className="blue">Выключено</span> */}
                    </div>
                </div>

                <div id="ventTempSpeed">
                    <div className="ventParams">
                        <div className="ventParamItem">
                            <span className="ventValue">
                                {currTemp.toFixed(1)} C°
                            </span>
                            <span className="ventText">
                                Current <br />
                                Temperature
                            </span>
                        </div>
                        <div className="vLine"></div>
                        <div className="ventParamItem">
                            <span className="ventValue">{setTemp} C°</span>
                            <span className="ventText">
                                Set <br />
                                Temperature
                            </span>
                        </div>
                    </div>
                    <div className="ventParams">
                        <div className="ventParamItem">
                            <span className="ventValue">{setSpeed} x</span>
                            <span className="ventText">
                                Set FAN
                                <br />
                                Speed {minSpeed}-{maxSpeed}
                                <br />
                                {/* <span style={{ fontSize: "8px" }}>0-12</span> */}
                            </span>
                        </div>
                    </div>
                </div>
                <BtnEntry
                    isActive={true}
                    extraStyle={{ marginRight: "20px", marginTop: "16px" }}
                    text="Control"
                    onClickAction={() =>
                        navigate(`/buildings/${objIds.buildingId}/ventilation`)
                    }
                />
            </div>
        </div>
    );
};

export default BVentilation;
