import React from "react";
import { Box, style } from "@mui/system";
import Button from "@mui/material/Button";
import { paletteColor } from "../utils/paletteColors";

interface IBtnEntry {
    isActive: boolean;
    text?: string;
    onClickAction?: () => void;
    extraStyle?: object;
}

const BtnEntry: React.FC<IBtnEntry> = ({
    isActive,
    text = "",
    onClickAction,
    extraStyle,
}: IBtnEntry) => {
    const style = {
        ...extraStyle,
        cursor: isActive ? "auto" : "not-allowed !important",
        pointerEvents: "auto !important",
        backgroundColor: paletteColor.BLUE,
        color: paletteColor.WHITE,
        fontSize: "0.75rem",
        fontWeight: "bold",
        borderRadius: "100px",
        width: "105px",
        height: "36px",
        // textTransform: "none",
    };

    return (
        <Box>
            <Button
                disabled={!isActive}
                onClick={onClickAction}
                variant="contained"
                sx={style}
            >
                {text}
            </Button>
        </Box>
    );
};

export default BtnEntry;
