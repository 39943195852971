import { getToken } from "./useToken";
import axios from "axios";


let protocol = window.location.protocol;
let hostname = window.location.hostname;

let rootUrl = protocol === "https:" ? "https://smartoffice-plc.qivisor.com" : "http://192.168.55.93:1880";



async function getData(urlPath: string, updateState: Function, abortController: any) {
    const token = getToken();
    const headers = {
        "x-hash-signature": token,
    };

    // console.log("protocol: ", protocol);
    // console.log("hostname: ", hostname);
    // console.log("rootUrl: ", rootUrl);

    await axios
        .get(rootUrl + urlPath, {
            headers: { 
                ...headers,
                signal: abortController.signal
            },
        })
        .then((res) => {
            console.log("getData.res: ", res);
            updateState(res.data);
        })
        .catch((err) => {
            console.error(err);
            // navigate("/login");
            // window.location.replace("/login");
        });
}



async function putData(urlPath: string, data: any) {
    await axios
        .put(rootUrl + urlPath, data)
        .then((res) => {
            console.log("getData.res: ", res);
        })
        .catch((err) => {
            console.error(err);
            // window.location.replace("/login");
        });
}



async function postData(urlPath: string, data: any) {
    return await axios
        .post(rootUrl + urlPath, data)
        .then((res) => res)
        .catch((err) => err);
}

export { getData, putData, postData };
