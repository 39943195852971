import { useEffect, useRef } from "react";

type TFnCallback = () => void;

export function useInterval(callback: TFnCallback, delay: number) {
    const savedCallback = useRef(callback);

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            typeof savedCallback.current === "function"
                ? savedCallback.current()
                : (() => 0)();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay]);
}
