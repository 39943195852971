import React from "react";
import "../../styles/building.css";
import "../../styles/grid_layout.css";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { paletteColor } from "../../utils/paletteColors";

interface ITempPower {
    currentTemp: number;
    currentPower: number;
}

const TempPower: React.FC<ITempPower> = ({
    currentTemp,
    currentPower,
}: ITempPower) => {
    return (
        <div className="col col-6">
            <div className="viewBox TempPower">
                <div className="TempPowerTemplate">
                    <ThermostatOutlinedIcon
                        style={{
                            fill: paletteColor.WHITE,
                            transform: "scale(4.2)",
                        }}
                    />
                    <div className="TempPowerNumTemplate">
                        {currentTemp.toFixed(1)} C°
                    </div>
                    <div className="TempPowerTextTemplate">
                        Room <br />
                        temperature
                    </div>
                </div>
                <div className="TempPowerTemplate">
                    <BoltOutlinedIcon
                        style={{
                            fill: paletteColor.WHITE,
                            transform: "scale(4.2)",
                        }}
                    />
                    <div className="TempPowerNumTemplate">
                        {currentPower} kWh
                    </div>
                    <div className="TempPowerTextTemplate">
                        Power <br />
                        consumption
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TempPower;
