import React from "react";
import '../../styles/grid_layout.css';
import '../../styles/building.css';
import { ReactComponent as Off } from '../../svg/off.svg';
import { ReactComponent as BAuto } from '../../svg/bAuto.svg';
import BtnEntry from "../BtnEntry";

const BAutomation: React.FC = () => {
    return (
        <div className="col col-4">
            <div className="viewBox automation inactive">
                <div className="iconTextState">
                    <div className="iconText">
                        <BAuto />
                        <span>
                            Overall <br />
                            Automation
                        </span>
                    </div>
                    <div className="state">
                        <Off />
                        <span className="blue">Not available</span>
                    </div>
                </div>
                {/* <button className="btn settings top">Настроить</button> */}
                <BtnEntry
                    isActive={false}
                    extraStyle={{ marginRight: "20px", marginTop: "16px" }}
                    text="Control"
                // onClickAction={() =>{}}
                />
            </div>
        </div>
    )
}
export default BAutomation;