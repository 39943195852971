import React from "react";
import "../../styles/ventilation.css";
import "../../styles/grid_layout.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ThermostatOutlinedIcon from "@mui/icons-material/ThermostatOutlined";

interface ITemp {
    currentTemp: number;
}

const Temp: React.FC<ITemp> = ({ currentTemp }: ITemp) => {
    return (
        <Grid className="col col-6">
            <div className="viewBox CurrTemp">
                <ThermostatOutlinedIcon
                    sx={{ fill: "white", transform: "scale(4.2)" }}
                />
                <div id="CurrTempNumText">
                    <div id="VentCurrTempNum">{currentTemp.toFixed(1)} C°</div>
                    <div id="VentCurrTempText">
                        Current
                        <br />
                        Temperature
                    </div>
                </div>
            </div>
        </Grid>
    );
};

export default Temp;
