import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../styles/ventilation.css";
import "../../styles/grid_layout.css";
import SpeedIcon from "@mui/icons-material/Speed";
import { paletteColor } from "../../utils/paletteColors";
import { putData } from "../../api/useApi";

interface ISetSpeed {
    currentSetSpeed: number;
    minSpeed: number;
    maxSpeed: number;
}

const SetSpeed: React.FC<ISetSpeed> = ({
    currentSetSpeed,
    minSpeed,
    maxSpeed,
}: ISetSpeed) => {
    let objIds = useParams();
    // const url = `${process.env.REACT_APP_URL_DATA_API}/buildings/${objIds.buildingId}/ventilation`;
    const urlPath = `/buildings/${objIds.buildingId}/ventilation`;
    const [setSpeed, setSetSpeed] = useState(currentSetSpeed);

    useEffect(() => {
        setSetSpeed(currentSetSpeed);
    }, [currentSetSpeed]);

    function handleBtnClick(num: number) {
        if (setSpeed + num > maxSpeed || setSpeed + num < minSpeed) {
            return;
        }
        setSetSpeed(setSpeed + num);
        putData(urlPath, { setSpeed: setSpeed + num });
    }

    return (
        <div className="col col-3">
            <div className="viewBox SetSpeed">
                <div id="SpeedIconText">
                    <SpeedIcon
                        style={{
                            fill: paletteColor.LIGHT_BLUE,
                            transform: "scale(3)",
                        }}
                    />
                    <div id="SpeedTextTemplate">
                        Set FAN
                        <br />
                        Speed {minSpeed}-{maxSpeed}
                    </div>
                </div>
                <div className="SetPlusMinus">
                    <span className="PlusMinusText">{setSpeed} x</span>
                    <div className="PlusMinus">
                        <button
                            className="Plus"
                            onClick={() => handleBtnClick(1)}
                        >
                            +
                        </button>
                        <button
                            className="Minus"
                            onClick={() => handleBtnClick(-1)}
                        >
                            -
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetSpeed;
