import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { paletteColor } from "../utils/paletteColors";

interface ITopBar {
    isBackBtn?: boolean;
    isMenuBtn?: boolean;
    onClickMenu?: () => void;
    isEditBtn?: boolean;
    onClickEdit?: () => void;
    backLink?: string;
    headerName: string;
}

const TopBar: React.FC<ITopBar> = ({
    isBackBtn,
    isMenuBtn,
    onClickMenu,
    isEditBtn,
    onClickEdit,
    backLink,
    headerName,
}: ITopBar) => {
    let navigate = useNavigate();

    const iconSyle = {
        width: "40px",
        height: "40px",
    };

    const posLeftStyle = {
        marginLeft: 0,
        marginRight: "auto",
    };

    const posRightStyle = {
        marginLeft: "auto",
        marginRight: 0,
    };

    const viewBoxStyle = {
        display: "flex",
        flexWrap: "nowrap",
        height: "52px",
        width: "100%",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "6px",
    };

    const headerTextStyle = {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        left: 0,
        right: 0,
        margin: "0 90px",
        color: paletteColor.BLUE,
    };

    return (
        <Box sx={viewBoxStyle}>
            <Box sx={posLeftStyle}>
                {isMenuBtn ? (
                    <IconButton disabled={true} onClick={onClickMenu}>
                        <MenuRoundedIcon
                            sx={{ ...iconSyle, fill: paletteColor.GRAY }}
                        />
                    </IconButton>
                ) : null}
                {isBackBtn ? (
                    <IconButton onClick={() => navigate(backLink || "#")}>
                        <ArrowBackIosRoundedIcon
                            sx={{ ...iconSyle, fill: paletteColor.LIGHT_BLUE }}
                        />
                    </IconButton>
                ) : null}
            </Box>

            <Box sx={headerTextStyle}>
                <h1>{headerName}</h1>
            </Box>

            <Box sx={posRightStyle}>
                {isEditBtn ? (
                    <IconButton disabled={true} onClick={onClickEdit}>
                        <EditOutlinedIcon
                            sx={{ ...iconSyle, fill: paletteColor.GRAY }}
                        />
                    </IconButton>
                ) : null}
            </Box>
        </Box>
    );
};

export default TopBar;
