export const paletteColor = {
    DARK_BLUE: "#0B0D36",
    BLUE: "#3754DB",
    LIGHT_BLUE: "#4092F5",
    DARK_GRAY_1: "#898989",
    DARK_GRAY_2: "#C4C4C4",
    GRAY: "#E2E2E2",
    LIGHT_GRAY: "#F4F4F4",
    WHITE: "#FFFFFF",
    RED: "#FF0000",
    ORANGE: "#FFAD4C",
    SWITCH_BLUE: "#4C6FFF",
    SWITCH_GRAY_1: "#D5E4F7",
    SWITCH_GRAY_2: "#A1B0CB",
};
