import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ToggleSwitch from "../ToggleSwitch";
import "../../styles/building.css";
import "../../styles/grid_layout.css";
import PowerIcon from "@mui/icons-material/Power";
import { paletteColor } from "../../utils/paletteColors";
import { putData } from "../../api/useApi";

interface IRosette {
    rosette: boolean;
}

const Rosette: React.FC<IRosette> = ({ rosette }: IRosette) => {
    let objIds = useParams();
    const urlPath = `/buildings/${objIds.buildingId}/rooms/${objIds.roomId}`;
    const [rosetteState, setRosetteState] = useState(rosette);

    useEffect(() => {
        setRosetteState(rosette);
    }, [rosette]);


    function handleChackbox(e: React.ChangeEvent<HTMLInputElement>) {
        // e.preventDefault();
        setRosetteState(e.target.checked);
        putData(urlPath, { rosette: e.target.checked });
    }

    return (
        <div className="col col-3">
            <div className="viewBox WhiteTemplate">
                <div className="TemplateIconText">
                    <PowerIcon
                        style={{
                            fill: paletteColor.LIGHT_BLUE,
                            transform: "scale(3)",
                        }}
                    />
                    <div className="TemplateText">Power Sockets</div>
                </div>
                <div className="TemplateSwitch">
                    <ToggleSwitch
                        onChange={handleChackbox}
                        checked={rosetteState}
                    />
                </div>
            </div>
        </div>
    );
};

export default Rosette;
