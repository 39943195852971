import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { paletteColor } from "../utils/paletteColors";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 54,
    height: 30,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 26,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(21px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 4,
        color: paletteColor.SWITCH_GRAY_2,
        "&.Mui-checked": {
            transform: "translateX(23px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? "#177ddc"
                        : paletteColor.SWITCH_BLUE,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        // boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 23,
        height: 22,
        borderRadius: 12,
        transition: theme.transitions.create(["width"], {
            duration: 50,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 15,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : paletteColor.SWITCH_GRAY_1,
        boxSizing: "border-box",
    },
}));

const ToggleSwitch = ({ onChange, checked }: any) => {
    return (
        <CustomSwitch
            inputProps={{ "aria-label": "ant design" }}
            onChange={(e) => onChange(e)}
            checked={checked}
        />
    );
};

export default ToggleSwitch;
