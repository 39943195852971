// import { useState } from "react";
import { IresData } from "../pages/Login";

// export default function useToken() {
//     const getToken = (): string => {
//         const tokenString = localStorage.getItem("token") || "{}";
//         const userToken = JSON.parse(tokenString);
//         return userToken?.token || "";
//     };

//     const [token, setToken] = useState<string>(getToken());

//     const saveToken = (userToken: IresData): void => {
//         localStorage.setItem("token", JSON.stringify(userToken));
//         setToken(userToken.token);
//     };

//     return {
//         setToken: saveToken,
//         token,
//     };
// }

const getToken = (): string => {
    const tokenString = localStorage.getItem("token") || "{}";
    const userToken = JSON.parse(tokenString);
    return userToken?.token || "";
};


const setToken = (userToken: IresData): void => {
    localStorage.setItem("token", JSON.stringify(userToken));
};


export {getToken, setToken};
